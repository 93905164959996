import React from "react"
import EditorJS from 'react-editor-js'
import { useState } from "react"
import { Fragment } from "react"

import List from "@editorjs/list"
import Image from "@editorjs/image"
import Header from "@editorjs/header"
import Delimiter from "@editorjs/delimiter"
import SimpleImage from "@editorjs/simple-image"
import Table from "@editorjs/table"

import EditorCrashForm from "./EditorCrashForm"
import CrashForm from "../content/CrashForm"
import EditorSyncTables from "./EditorSyncTables"
import UpdateDataButton from "../content/UpdateDataButton"
import EditorLoginForm from "./EditorLoginForm"
import LoginForm from "../content/LoginForm"

// import Embed from "@editorjs/embed"
// import Table from "@editorjs/table"
// import Warning from "@editorjs/warning"
// import Code from "@editorjs/code"
// import LinkTool from "@editorjs/link"
// import Raw from "@editorjs/raw"
// import Quote from "@editorjs/quote"
// import Marker from "@editorjs/marker"
// import CheckList from "@editorjs/checklist"
// import InlineCode from "@editorjs/inline-code"

let editorInstance = null

export default function Editor({logged, data = null, updatePageContent, ...props}) {

	const [readOnly, setReadOnly] = useState(true)

	const [savingState, setSavingState] = useState(0)

	const handleToggleReadonly = () => {
		setReadOnly(!readOnly)
	}

	const handleSaveChanges = () => {
		setSavingState(1)
		setTimeout(() => {
			editorInstance.save()
			.then(async data => {
				updatePageContent(data)
				.then(() => {
					setSavingState(2)
				})
				.catch(error => {
					console.error('updatePageContent: ', error)
					setSavingState(3)
				})
				setTimeout(() => {
					setSavingState(0)
				}, 600.0)
			})
			.catch(error => {
				console.error(error)
			})
		}, 500.0)

		// updatePageContent()

		// setTimeout(() => {
		// 	setSavingState(2)
		// 	setTimeout(() => {
		// 		setSavingState(0)
		// 	}, 1000.0)
		// }, 1000.0)
	}

	return (
		<>
			{
				logged ? 
					<>
						<button className="admin-button" onClick={handleToggleReadonly}>{readOnly ? 'Tryb edycji' : 'Podgląd'}</button>	
						{
							readOnly ?
								null
							:
								<button className="admin-button" onClick={handleSaveChanges}>
									{savingState == 0 ? 'Zapisz zmiany' : savingState == 1 ? 'Zapisywanie...' : savingState == 2 ? 'Zapisano!' : 'Błąd podczas zapisywania'}
								</button>
						}
					</>
				:
					null
			}
			{	
				readOnly ?
					convertJsonToHtml(data)
				:
					<>
						{/* <button onClick={handlePrint}>Print editor data</button> */}
						<EditorJS 
							// onReady={disabled ? () => disableEditor(true) : () => disableEditor(false)}
							instanceRef={instance => editorInstance = instance}
							data={data}
							tools={{
								// table: Table,
								crashForm: EditorCrashForm,
								syncTables: EditorSyncTables,
								loginForm: EditorLoginForm,
								// embed: Embed,
								// table: Table,
								// marker: Marker,
								list: List,
								// warning: Warning,
								// code: Code,
								// linkTool: LinkTool,
								// bigImage: Image,
								// raw: Raw,
								header: Header,
								// quote: Quote,
								// checklist: CheckList,
								image: SimpleImage,
								// delimiter: Delimiter,
								// inlineCode: InlineCode,
							}}
						/>
					</>
			}
		</>
	)


	function getFormatedText(string) {
		const markup = {
			__html: string
		}
		return <span dangerouslySetInnerHTML={markup} />
	}

	function convertJsonToHtml(json) {
			
		if (json) {
			let ret = json.blocks.map((block, index) => {
				switch (block.type) {
					case 'header':
						return React.createElement(`h${block.data.level}`, {key: index}, getFormatedText(block.data.text))

					case 'paragraph':
						return <p key={index}>{getFormatedText(block.data.text)}</p>

					case 'delimiter':
						return <hr key={index} />

					case 'image':
						// return `<img class="img-fluid" src="${block.data.file.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`
						return (
							<Fragment key={index}>
								<img className="img-fluid" alt={block.data.caption} src={block.data.url} title={block.data.caption} />
								<em>{getFormatedText(block.data.caption)}</em>
							</Fragment>
						)

					case 'list':
						return <ul key={index}>
							{
								block.data.items.map((li, index) => <li key={index}>${getFormatedText(li)}</li>)
							}
						</ul>

					case 'crashForm':
						return <CrashForm key={index} />

					case 'syncTables':
						return <UpdateDataButton key={index} logged={logged} />

					case 'loginForm':
						return <LoginForm key={index} />

					default:
						console.log('Unknown block type', block.type)
						console.log(block)
						return <div key={index}></div>
				}
			})
			return <div>{ret}</div>
		}

		return (<div></div>)
	}

}