import React from "react";

import { Switch, Route } from "react-router-dom";
import Page from "./Page";

export default function MainPage({logged, websiteContent, updatePageContent, ...props}) {

	return (
		<Switch>
			{
				websiteContent.pages.map((page, index) => (
					index === 0 ?
						<Route key={index} exact path={[page.buttonUrl, "/"]}>
							<Page logged={logged} page={page} updatePageContent={content => updatePageContent(index, content)}/>
						</Route>
					:
						<Route key={index} path={page.buttonUrl}>
							<Page logged={logged} page={page} updatePageContent={content => updatePageContent(index, content)}/>
						</Route>
				))
			}
		</Switch>
	)

}
