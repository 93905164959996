import XLSX from 'xlsx'

import Database from '../services/global/Database'

const readFile = file => new Promise((resolve, reject) => {
	const fr = new FileReader()
	fr.onload = () => resolve(fr.result)
	fr.readAsText(file)
})

const readBinaryFile = file => new Promise((resolve, reject) => {
	const fr = new FileReader()
	fr.onload = () => resolve(fr.result)
	fr.readAsBinaryString(file)
})

const inlineSourceData2JsonData = inlineSourceData => {
	const splited = inlineSourceData.split(':')
	const type = splited[0]
	if (type === 'zevercloud') {
		return {source: type, apiKey: splited[1]}
	}
	else if (type === 'sunnyportal') {
		return {source: type, pageKey: splited[1]}
	}
	else if (type === 'static') {
		return {source: type, value: parseFloat(splited[1])}
	}
	else {
		return {source: 'none'}
	}
}  

const jsonData2InlineSourceData = jsonData => {
	if (jsonData.source === 'zevercloud') {
		return `zevercloud:${jsonData.apiKey}`
	}
	else if (jsonData.source === 'sunnyportal') {
		return `sunnyportal:${jsonData.pageKey}`
	}
	else if (jsonData.source === 'static') {
		return `static:${jsonData.value}`
	}
	else {
		return `none`
	}
}

const getPanelsXlJson = panels => panels.map(panel => {
	return {
		...panel, 
		data: jsonData2InlineSourceData(panel.data)
	}
})

const getCollectorsXlJson = collectors => collectors

const getAutoSizedColumns = dataXlJson => {

	const rows = {}
	dataXlJson.forEach(row => {
		// console.log(row)
		Object.entries(row).forEach(([column, value]) => {
			rows[column] = rows[column] ? [...rows[column], value] : [column, value]
		})
	})

	const wscols = Object.entries(rows).map(([,column]) => (
		{ wch: Math.max(...column.map(value => String(value).length)) + 2.0 }
	))

	return wscols
}

const workbook2Config = wb => {
	const config = {}
	Object.entries(wb.Sheets).forEach(([wsName, wsData]) => {
		// const x = XLSX.utils.sheet_to_json(wsData)
		
		if (wsName === 'panels') {
			const json = XLSX.utils.sheet_to_json(wsData)
			config[wsName] = json.map(panel => ({...panel, data: inlineSourceData2JsonData(panel.data)}))
		}
		else if (wsName === 'collectors') {
			const json = XLSX.utils.sheet_to_json(wsData)
			config[wsName] = json
		}
	})
	return config
}

class ConfigManager {

	exportConfig() {
		return Database.downloadWebsiteConfig()
		.then(config => {
			const collectorsXlData = getCollectorsXlJson(config.collectors)
			const wsCollectors = XLSX.utils.json_to_sheet(collectorsXlData)

			const panelsXlData = getPanelsXlJson(config.panels)
			const wsPanels = XLSX.utils.json_to_sheet(panelsXlData)

			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, {...wsCollectors, '!cols': getAutoSizedColumns(collectorsXlData)}, 'collectors')
			XLSX.utils.book_append_sheet(wb, {...wsPanels, '!cols': getAutoSizedColumns(panelsXlData)}, 'panels')
			XLSX.writeFile(wb, 'config.xlsx')
		})
	}

	importConfig(blob) {
		return readBinaryFile(blob)
		.then(async data => {
			//'base64' | 'binary' | 'buffer' | 'file' | 'array' | 'string'
			const wb = XLSX.read(data, {type: 'binary'})
			const newConfig = workbook2Config(wb)
			console.log(JSON.stringify(newConfig))
			// XLSX.writeFile(wb, 'importedConfig.xlsx')
			Database.updateWebsiteConfig(newConfig)
		})
		.catch(error => {
			console.error('Reading data error: ', error)
		})
	}
}

export default new ConfigManager