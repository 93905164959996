import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Navbar from "../components/navbar/Navbar";
import MainPage from "../pages/MainPage";

import DataPages from "../assets/data_pages" 
import { TablesDataContext } from "./Context";
import Database from "../services/global/Database";
import Auth from "../services/global/Auth";

function Main({...props}) {

	const [websiteContent, setWebsiteContent] = useState(null)
	const [websiteConfig, setWebsiteConfig] = useState(null)
	
	const [logged, setLogged] = useState(null)

	useEffect(() => {
		Auth.onAuthStateChanged(user => {
			setLogged(user)
		})

		Database.downloadWebsiteContent()
		.then(data => setWebsiteContent(data))
		.catch(error => console.error(error))

	}, [])

	const updatePageContent = (index, content) => {
		const copy = {...websiteContent}
		copy.pages[index].content = content
		setWebsiteContent(copy)
		return Database.updateWebsiteContent(copy)
	}

	const getCollectionsFromLocalStorage = () => {
		const rawStr = localStorage.getItem('collections')
		return rawStr ? JSON.parse(rawStr) : {}
	}

	const setCollectionsAndLocalStorage = collections => {
		setCollections(collections)
		if (collections) {
			localStorage.setItem('collections', JSON.stringify(collections))
		}
	} 

	const [currentPage, setCurrentPage] = useState("reportCrash")
	const [dataPages, setDataPages] = useState(DataPages)
	const [collections, setCollections] = useState(getCollectionsFromLocalStorage())

	const changePage = (pageId) => {
		setCurrentPage(pageId)
	}

	return (
		<div className="main">
			<Navbar websiteContent={websiteContent} logged={logged}/>
			<TablesDataContext.Provider value={{
				collections: collections,
				setCollections: setCollectionsAndLocalStorage
			}}>
				<div className="navbar-ghost"></div>
				{
					websiteContent ?
						<MainPage websiteContent={websiteContent} logged={logged} updatePageContent={updatePageContent}/>
					:
						<div className="loading-website">
							<div>Wczytywanie strony...</div>			
						</div>
				}
			</TablesDataContext.Provider>
		</div>
	)
}

export default withRouter(Main);