import React, { Component, useState } from "react";


export default function FormLabel({label = '', validationMessage = '', ...props}) {
	
	return (
		<div className='form-element-label'>
			<label className='label'>{label}</label>
			{
				validationMessage ? 
					<label className='validation-msg'>{validationMessage}</label>
				: null
			}
		</div>
	)
}
