import React, { useContext } from "react"

import Table from "./Table"
import { TablesDataContext } from "../../core/Context";
import { HorizontalBar, Bar } from 'react-chartjs-2'

export default function SyncTable({collectionName, ...props}) {

	const context = useContext(TablesDataContext)

	try {
		const collection = context.collections[collectionName]
		const preparedData = collection.data.map(row => {
			return Object.entries(row).reduce((acc, [column, value]) => {
				acc[column] = isNaN(value) ? value : parseFloat(value).toFixed(2)
				return acc
			}, {})
		})

		const rawChartLabels = preparedData.map(row => row.type.split('-')[0].trim())

		const rawChartValues = preparedData.map(row => {
			return isNaN(row.yield) ? 0 : parseFloat(row.yield)
		})

		// console.log(rawChartValues)

		const chartData = {
			labels: rawChartLabels,
			datasets: [
				{
					label: 'Uzysk',
					backgroundColor: 'rgba(255,99,132,0.2)',
					borderColor: 'rgba(255,99,132,1)',
					borderWidth: 1,
					hoverBackgroundColor: 'rgba(255,99,132,0.4)',
					hoverBorderColor: 'rgba(255,99,132,1)',
					data: rawChartValues	
				}
			]
		}

		// var x = []
		// preparedData = [...preparedData, ]

		const sumRow = {
			uid: '',
			type: 'Suma:',
			yield: preparedData.reduce((sum, row) => {
				const value = parseFloat(row.yield)
				return isNaN(value) ? sum : sum + value 
			}, 0.0)
		}
		sumRow.yield = sumRow.yield.toFixed(2)

		const finalData = [...preparedData, sumRow]
		// console.log(preparedData)

		// console.log(chartData)

		return (
			<>
				<Table columns={collection.columns} data={finalData} />
				<div className="chart">
					<HorizontalBar  
						data={chartData} 
						// width={'100%'}
						height={200}
						options={{
							scales: {
								yAxes: [{
									barPercentage: 0.8
									// barThickness: 20.0
								}],
								xAxes: [{									
									ticks: {
										beginAtZero:true,
										callback: function(value, index, values) {
												return value + ' kWh';
										}
									}
								}]
							},
							// responsive: true,
							maintainAspectRatio: false
						}}
					/>
				</div>
			</>
		)

	}
	catch(error) {
		console.error(error)
		return <div>Oczekiwanie na pobranie danych...</div>
	}

}