import React, { Component } from "react";

export default function FormButton({label, onClick, className, disabled=false, ...props}) {
	
    return (
        <div className={`form-element ${className}`}>
            <button onClick={onClick ? onClick : () => {}} disabled={disabled}>
                {label}
            </button>
        </div>
    )

}
