import React, { useState } from "react";
import Validator from 'validator';

import FormInput from "../form/FormInput";
import FormButton from "../form/FormButton";
import FormCheckbox from "../form/FormCheckbox";
import FormDropdown from "../form/FormDropdown";
import useInput from "../../hooks/useInput";
import useCheckbox from "../../hooks/useCheckbox";
import Api from "../../api/Api";


const installations = [
    {
        name: "Kolektory słoneczne",
        problems: [
            "Wyciek wody",
            "Wyciek glikolu",
            "Spadek ciśnienia",
            "Brak ciepłej wody",
            "Sterownik \"martwy\"",
            "Wyświetla się błąd",
            "Inne"
        ],
        parameters: [
            "Godzina odczytu [hh:mm]",
            "T1 (na kolektorach) [°C]",
            "T2 (na dole zbiornika) [°C]",
            "Ciśnienie glikolu [BAR]",
            "Ilość kolektorów [sztuk]"
        ]
    },
    {
        name: "Panele fotowoltaiczne",
        problems: [
            "Falownik - wyłącza się",
            "Zabezpieczenie wyskakuje",
            "Wyświetla się błąd",
            "Brak połączenia z internetem",
            "Inne",
        ],
        parameters: [
            "Ilość paneli [sztuk]"
        ]
    },
    {
        name: "Pompa ciepła",
        problems: [
            "Wyciek wody",
            "Sterownik \"martwy\"",
            "Brak ciepłej wody",
            "Wyświetla się błąd",
            "Inne",
        ],
        parameters: [
            "Temperatura CWU [st. C]"
        ]
    }//,
    // {
    //     name: "Kocioł na biomasę",
    //     problems: [
    //         "Wyciek wody",
    //         "Brak podawania palletu",
    //         "Spadek ciśnienia",
    //         "Brak ciepłej wody",
    //         "Sterownik \"marwy\"",
    //         "Wyświetla się błąd",
    //         "Inne",
    //     ],
    //     parameters: [
    //         "Ustawiona temperatura kotła [st. C]",
    //         "Ustawiona temperatura CWU [st. C]"
    //     ]
    // }
]

const installationsNames = Object.entries(installations).map(([key, value]) => value.name)

export default function CrashForm(props) {
    
    const [name, onChangeName] = useInput('')
    const [email, onChangeEmail] = useInput('')
    const [phone, onChangePhone] = useInput('')
    const [address, onChangeAddress] = useInput('')
    
    const [selectedInstallation, setSelectedInstallation] = useState(-1)
    const [selectedProblem, setSelectedProblem] = useState(-1)
    
    const inputs = [
        useInput(''),
        useInput(''),
        useInput(''),
        useInput(''),
        useInput('')
    ]
    
    const [description, onChangeDescription] = useInput('')
    const [selectedStatement, toggleStatement] = useCheckbox(false)

    const [sendingState, setSendingState] = useState(0)
    const [sendingError, setSendingError] = useState(null)
    
    // Validations messages
    
    const [nameValidationMsg, setNameValidationMsg] = useState('')
    const [emailValidationMsg, setEmailValidationMsg] = useState('')
    const [phoneValidationMsg, setPhoneValidationMsg] = useState('')
    const [addressValidationMsg, setAddressValidationMsg] = useState('')
    const [selectedInstallationValidationMsg, setSelectedInstallationValidationMsg] = useState('')
    const [selectedProblemValidationMsg, setSelectedProblemValidationMsg] = useState('')
    const inputsValidationMsgs = [
        useState(''),
        useState(''),
        useState(''),
        useState(''),
        useState('')
    ]
    const [descriptionValidationMessage, setDescriptionValidationMessage] = useState('')
    const [selectedStatementValidationMessage, setSelectedStatementValidationMessage] = useState('')

    // ---

    const resetInput = () => {
        inputs.forEach(([,,setValue]) => setValue(''))
    }

    const handleSelectInstallation = (index) => {
        setSelectedInstallation(index)
        setSelectedProblem(-1)
        resetInput()
    }

    const handleSelectProblem = (index) => {
        setSelectedProblem(index)
    }

    const singleValidation = (okCond, setMsg, msg) => {
        if (okCond) setMsg('')
        else setMsg(msg)
        return Boolean(okCond)
    }

    const singleValidationWithArrays = (okCondArray, setMsg, msgArray) => {
        const ok = okCondArray.reduce((ok, cond, index) => {
            if (ok && !cond) setMsg(msgArray[index])
            return ok && cond
        }, true)
        if (ok) setMsg('')
        return ok
    }

    const validate = () => {
        let ok = true
        ok = singleValidation(name, setNameValidationMsg, 'To pole nie może być puste') && ok
        ok = singleValidation(email, setEmailValidationMsg, 'To pole nie może być puste') && ok
        ok = singleValidationWithArrays([email, Validator.isEmail(email)], setEmailValidationMsg, ['To pole nie może być puste', 'Podaj poprawny email']) && ok
        ok = singleValidation(phone, setPhoneValidationMsg, 'To pole nie może być puste') && ok
        ok = singleValidation(address, setAddressValidationMsg, 'To pole nie może być puste') && ok
        ok = singleValidation(selectedInstallation != -1, setSelectedInstallationValidationMsg, 'Wybierz rodzaj instalacji') && ok
        ok = singleValidation(selectedProblem != -1, setSelectedProblemValidationMsg, 'Wybierz rodzaj awarii') && ok
        if (selectedInstallation != -1) {
            ok = inputs.slice(0, installations[selectedInstallation].parameters.length).reduce((ok, [value,], index) => singleValidation(value, inputsValidationMsgs[index][1], 'To pole nie może być puste') && ok, ok)
        }
        ok = singleValidation(description, setDescriptionValidationMessage, 'To pole nie może być puste') && ok
        ok = singleValidation(selectedStatement, setSelectedStatementValidationMessage, 'Oświadczenie musi być zaznaczone') && ok

        return ok
    }

    const testParams = {	
        "name": "name_value",
        "email": "damomis881@lowdh.com",
        "address": "address_value",
        "installation": "installation_value",
        "problem": "problem_value",
        "phone": "phone_value",
        "values": "values_value",
        "description": "description_value"
    }

    const handleSubmit = () => {
        const ok = validate()
        // const ok = true
 
        if (ok) {
            const installation = installations[selectedInstallation]
            const problem = installation.problems[selectedProblem]
            const values = `<ul>${installation.parameters.map((parameter, index) => (
                `<li><strong>${parameter}:</strong> ${inputs[index][0]}</li>`
            )).join('<br>')}</ul>`

            const emailParameters = {
                name: name,
                email: email,
                phone: phone,
                address: address,
                installation: installation.name,
                problem: problem,
                description: description.replace(/\n/g, '<br>'),
                values: values
            }

            setSendingState(1)
            setTimeout(() => {

                Api.sendEmail(emailParameters)
                .then(res => {
                    setSendingState(2)
                })
                .catch(error => {
                    setSendingState(0)
                    console.error("MailSendingError: ", error)
                })

                console.log('Mail sent.')
            }, 500.0)
        }
    }

    return (
        <div>
            <div className="form-group">
                <h2>Zgłaszający</h2>
                <FormInput 
                    name="name" 
                    label="Imię i nazwisko zgłaszającego" 
                    validationMessage={nameValidationMsg} 
                    onChange={onChangeName}
                />
                <FormInput 
                    name="email" 
                    label="Adres email zgłaszającego" 
                    validationMessage={emailValidationMsg} 
                    onChange={onChangeEmail}
                />
                <FormInput 
                    name="phone" 
                    label="Numer telefonu zgłaszającego" 
                    validationMessage={phoneValidationMsg} 
                    onChange={onChangePhone}
                />
                <FormInput 
                    name="address" 
                    label="Adres instalacji" 
                    validationMessage={addressValidationMsg} 
                    onChange={onChangeAddress}
                />
            </div>

            <div className="form-group">
                <h2>Informacje o awarii</h2>
                <FormDropdown className="inline-element" label="Rodzaj instalacji" 
                    options={installationsNames}
                    placeholder="Wybierz..."
                    width="210px"
                    onChangeOption={handleSelectInstallation}
                    selectedOption={selectedInstallation}
                    validationMessage={selectedInstallationValidationMsg}
                />
                {
                    selectedInstallation !== -1 ?
                    <FormDropdown className="inline-element" label="Rodzaj awarii" 
                        options={installations[selectedInstallation].problems}
                        placeholder="Wybierz..."
                        width="210px"
                        onChangeOption={handleSelectProblem}
                        selectedOption={selectedProblem}
                        validationMessage={selectedProblemValidationMsg}
                    />
                    : null
                }
                {
                    selectedInstallation !== -1 ?
                        installations[selectedInstallation].parameters.map((parameter, index) => 
                            <FormInput 
                                value={inputs[index][0]} 
                                onChange={inputs[index][1]} 
                                key={index} 
                                label={parameter} 
                                validationMessage={inputsValidationMsgs[index][0]}
                            />
                        )
                    : null
                }            
            </div>

            <div className="form-group">
                <h2>Opis awarii</h2>
                <FormInput multiline 
                    name="crashDescription" 
                    label="Dodatkowe informacje dotyczące awarii" 
                    validationMessage={descriptionValidationMessage}
                    onChange={onChangeDescription}
                />
            </div>

            <FormCheckbox 
                label="Oświadczenie" 
                text="Oświadczam, że znane są mi zasady eksploatacji oraz warunki gwarancji instalacji i urządzeń. W przypadku stwierdzenia przez serwisanta wystąpienia powyższej awarii na skutek okoliczności nie objętych gwarancją wykonawcy i producenta urządzeń zobowiązuję się do pokrycia kosztów awarii w dniu jej usunięcia."
                selected={selectedStatement}
                toggle={toggleStatement}
                validationMessage={selectedStatementValidationMessage}
            />
            
            <div className="form-group">
                <FormButton 
                    label={sendingState ? ( sendingState === 1 ? "Trwa wysyłanie..." : "Wiadomość wysłana!") : "Wyślij zgłoszenie"} 
                    onClick={handleSubmit} 
                    disabled={sendingState}
                />
            </div>
        </div>
    )
}
