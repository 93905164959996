const DataPages = [
	{
		url: "/about",
		buttonName: "O projekcie",
		articles: [
			{
				title: "OZE w Gminie Krasnobród",
				content: `
\`\`\`image
/assets/about.png, 60%
\`\`\`
				`
			},
			{
				title: "Instytucja zarządzająca",
				content: `
Projekt jest  realizowany w ramach Osi Priorytetowej 4 Energia Przyjazna Środowisku, 
Działanie 4.1 Wsparcie wykorzystania OZE Regionalnego Programu Operacyjnego Województwa Lubelskiego na lata 2014-2020.\n
Umowa nr: RPLU.04.01.00-06-0127/16-00 została zawarta dnia 01.08.2018 roku.\n
**Całkowita wartość Projektu wynosi 5746933,00 zł z czego wydatki kwalifikowane Projektu wynoszą 52588600,00 zł.**\n
**Gmina Krasnobród uzyskała dofinansowanie w kwocie nieprzekraczającej 4469810,00 zł co stanowi 85,00% wydatków kwalifikowanych.**\n
Realizacja projektu zakończy się do końca 2020 roku. W ramach wniosku zainstalowanych zostanie około 400 instalacji OZE na terenie Gminy Krasnobród.
				`
			}
		]
	},
	{
		url: "/about-oze",
		buttonName: "Czym są OZE?",
		articles: [
			{
				title: "asd",
				content: "e231"
			}
		]
	},
	{
		url: "/installations",
		buttonName: "Rodzaje instalacji",
		articles: [
			{
				title: "Opis zamontowanych instalacji OZE"
			},
			{
				title: "Instalacje na kolektorach słonecznych",
				content: `
Zastosowano do ich budowy płaskie kolektory słonecznie firmy VIESSMANN co gwarantuje wysoką jakość i sprawność na długie lata eksploatacji. Dzięki takiej instalacji mamy ciepłą wodę przeznaczoną na cele mieszkaniowe którą nagrzewa słońce. Instalacje są dobrane do zapotrzebowania konkretnego gospodarstwa domowego w zależności od zapotrzebowania. Obok kolektorów umieszczanych zazwyczaj na dachu lub elewacji budynku mieszkalnego istotnym elementem układu jest również zbiornik i sterownik który zarządza  instalacją w sposób automatyczny i jak najbardziej efektywny. Instalacja stanowi w pełni ekologiczny układ wspomagający proces podgrzewania ciepłej wody w gospodarstwie domowym.			
`
			},
			{
				title: "Instalacje na panelach fotowoltaicznych",
				content: `
Zastosowano do ich budowy panele fotowoltaiczne firmy SHARP co gwarantuje wysoką jakość i sprawność na długie lata eksploatacji. Dzięki takiej instalacji mamy energię elektryczną przeznaczoną na cele mieszkaniowe. Produkowanie energii elektrycznej przez panele fotowoltaiczne jest możliwe dzięki zjawisku nazywanemu efektem fotowoltaicznym. Zjawisko to polega na tym, że w półprzewodniku powstaje siła elektromotoryczna. Co to oznacza? Najprościej rzecz ujmując, w jego wyniku energia słoneczna zostaje zamieniona na prąd stały, a dochodzi do tego w ogniwach fotowoltaicznych, z których składają się panele słoneczne. Następnie aby móc korzystać z prądu, który wytworzyła instalacja fotowoltaiczna niezbędny jest inwerter solarny (falownik). To urządzenie, przy pomocy którego prąd stały, jaki powstał z energii słonecznej, zostaje przekształcony w prąd zmienny o parametrach elektrycznych zgodnych z parametrami sieci publicznej. To znaczy na taki, który znajduje się w naszych gniazdkach elektrycznych. Instalacja ta jest zintegrowana z siecią elektryczną jest to instalacja on-grid, co oznacza, że dzięki niej możemy zużywać wyprodukowany prąd na bieżąco, a jego nadwyżkę przesyłać do sieci energetycznej. W momencie, gdy instalacja on-grid nie wytwarza odpowiedniej ilości energii elektrycznej (np. zimą lub wieczorem) możemy odebrać od sieci energetycznej 80% wyprodukowanej wcześniej nadwyżki. W ten sposób rachunki za prąd będą odpowiednio niższe i dodatkowo nie potrzebujemy stosować drogich akumulatorów do gromadzenia energii elektrycznej.
`
			},
			{
				title: "Instalacje na powietrznych pompach ciepła",
				content: `
Te instalacje integrują ze sobą zarówno korzyść posiadania energii elektrycznej ze słońca jak i energii cieplnej. Zasada działania jest taka sama jak oddzielnych instalacji fotowoltaicznej i pompy ciepła. Tego typu instalacje pozwalają w jeszcze większym zakresie wykorzystać energię słoneczną jak i tą która jest w otaczającym nas powietrzu.
`
			},
			{
				title: " Instalacje fotowoltaiczne z pompą ciepła",
				content: `
Te instalacje integrują ze sobą zarówno korzyść posiadania energii elektrycznej ze słońca jak i energii cieplnej. Zasada działania jest taka sama jak oddzielnych instalacji fotowoltaicznej i pompy ciepła. Tego typu instalacje pozwalają w jeszcze większym zakresie wykorzystać energię słoneczną jak i tą która jest w otaczającym nas powietrzu.
`
			}
		]
	},
	{
		url: "/faq",
		buttonName: "Często zadawane pytania",
		articles: [
			{
				title: "Poradnik dla użytkownika",
				content: `
**WAŻNE:** W przypadku chęci zgłoszenia awarii podajemy dane kontaktowe serwisu gwarancyjnego:
\n
\n
tel: 500478543
\n
\n
Email: serwis@domotermika.pl
\n
\n
Przy zgłaszaniu awarii należy zawsze podać adres instalacji i dane kontaktowe w szczególności aktualny numer telefonu. Mile widziane są również zdjęcia obrazujące stwierdzony problem na  instalacji.     		   
`
			},
			{
				title: "Najczęściej zadawane pytania",
				content: `
**Pytanie:** Czy brak odbioru ciepłej wody zgromadzonej w zasobniku, ma negatywny wpływ na instalację? Co jeśli wyjadę na urlop i nikt nie będzie korzystał z ciepłej wody?
\n
**Odpowiedź:** Nie odbieranie zgromadzonej w zasobniku solarnym wody powoduje wzrost jej temperatury. W skutek czego może dojść do rozszczelnienia instalacji glikolowej. Dlatego w przypadku dłużej nieobecności należy włączyć tryb „urlopowy”. W wyniku jego działania nagrzany zestaw solarny zostanie automatycznie schłodzony.
\n
**Pytanie:** Co oznacza kapanie z zaworu bezpieczeństwa?
\n
**Odpowiedź:** Zjawisko tzw. kapania wody z zaworu bezpieczeństwa występuję w momencie wzrostu ciśnienia wody wewnątrz zasobnika (od około 6 bar). Pojawiająca się woda nie jest jednak oznaką usterki i nie wymaga wezwania serwisu. Zaleca się podstawianie naczynia na ewentualną wodę jeśli nie ma w pomieszczeniu kratki ściekowej.
\n
**Pytanie:** W zasobniku panuje bardzo wysoka temperatura(80-95 st C), czy nie ulegnę poparzeniu?
\n
**Odpowiedź:** Zamontowany w każdej instalacji termostatyczny zawór mieszający chroni użytkownika systemu przed poparzeniem się gorącą wodą z zasobnika solarnego. Użytkownik pokrętłem może regulować indywidualnie temperaturę ciepłej wody.
`
			}
		]
	},
	{
		url: "/download",
		buttonName: "Do pobrania",
		articles: [
			{
				title: "",
				content: "",
			}
		]
	},
	{
		url: "/media",
		buttonName: "Multimedia",
		articles: [
			{
				title: "Prezentacja ze spotkania z mieszkańcami",
				content: "**Pobierz prezentację:** [29.07.2019 spotkanie KRASNOBRÓD kolektory](http://ozekrasnobrod.pl/wp-content/uploads/2020/05/29.07.2019-spotkanie-KRASNOBR%C3%93D-kolektory.pptx)",
			},
			{
				title: "Przykładowe instalacje",
				content: `
\`\`\`image
/assets/img1.jpg, 100%
\`\`\`
\`\`\`image
/assets/img2.jpg, 100%
\`\`\`
\`\`\`image
/assets/img3.jpg, 100%
\`\`\`
`,
			}
		]
	},
	{
		url: "/contact",
		buttonName: "Kontakt",
		articles: [
			{
				title: "Urząd gminy",
				content: `
**Gmina Krasnobród**  \nul. 3 Maja 36,  \n22-440 Krasnobród\n\n
Telefon 84 6607691, 846607692  \nFax 84 6607158  \nEmail: um@krasnobrod.pl
`,
			},
			{
				title: "Wykonawca / Serwis",
				content: `
**DOMOTERMIKA Krzysztof Majewski**  \nul. Polna 1E/6,  \n82-500 Kwidzyn\n\n
Tel. serwis: 500 478 543  \ne-mail: serwis@domotermika.pl
`,
			}
		]
	},
	{
		url: "/report-problem",
		buttonName: "Zgłoś awarię",
		articles: [
			{
				title: "Formularz zgłaszania awarii",
				content: `
\`\`\`crashForm
\`\`\`
`,
			}
		]
	},
	{
		url: "/check-yield",
		buttonName: "Zobacz uzyski\n\n(w czasie rzeczywistym)",
		articles: [
			{
				content: `
\`\`\`updateDataButton
\`\`\`
`,
			},
			{
				title: "Uzysk z instalacji na kolektorach",
				content: `
\`\`\`syncTable
collectors
\`\`\`
`,
			},
			{
				title: "Uzysk z instalacji na panelach folowolaticznych",
				content: `
\`\`\`syncTable
panels
\`\`\`
`,
			}
		]
	},
	{
		url: "/login",
		articles: [
			{
				title: "Logowanie",
				content: `
\`\`\`loginWindow
\`\`\`
`,
			}
		]
	}
]

export default DataPages
