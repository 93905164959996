import React from "react";
import MarkdownRenderer from "../content/MarkdownRenderer";
import { useRouteMatch, Link, useHistory, withRouter } from "react-router-dom";


const NavbarButton = withRouter(({label, exact = true, url, className, history, staticContext, ...props}) => {

	let match = useRouteMatch({
		path: url,
		exact: exact
	})

	const handleChangePage = () => {
		history.push(url)
	}

	return (
		<div className={`navbar-button ${match ? "selected" : ""} ${className}`} onClick={handleChangePage} {...props}>
			<MarkdownRenderer source={label} />
		</div>
	)
})

export default NavbarButton