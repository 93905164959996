import React, { Component } from "react";
import Main from "./Main";
import { 
  BrowserRouter as Router 
} from "react-router-dom";



// const Root = (props) => {
//   const location = useLocation()

//   React.useEffect(() => {
//     console.log("Location changed")
//   }, [location])

//   return (
//     <Router>
//       <Main />
//     </Router>
//   )
// }

// export default Root

export default class Root extends Component {

  render() {
    return (
      <Router>
        <Main />
      </Router>
    );
  }
}
