import React, { Component, useState } from "react";

import FormLabel from './FormLabel'

export default function FormInput({value, onChange, label, name, multiline, className, type = 'text', validationMessage = null, ...props}) {
	
	return (
		<div className={`form-element ${className}`} {...props}>
			<FormLabel label={label} validationMessage={validationMessage} />
			{
				multiline
				?
				<textarea className="bordered-input max-width vertical-resize" htmlFor={name} value={value} onChange={onChange}></textarea>
				:
				<input className="bordered-input max-width" type={type} name={name} value={value} onChange={onChange} />
			}
		</div>
	)
}
