import React from 'react' 
import useInput from "../../hooks/useInput"
import { withRouter } from 'react-router-dom'
import Auth from '../../services/global/Auth'
import FormButton from '../form/FormButton'
import FormInput from '../form/FormInput'

const LoginForm = withRouter(({history, staticContext, ...props}) => {

	const [email, handleEmailChange] = useInput('')
	const [password, handlePasswordChange] = useInput('')

	const handleLogin = () => {
		Auth.loginWithEmailAndPassword(email, password)
			.then(user => {
				console.log('User logged.')
				history.push('/about')
			})
			.catch(error => {
				console.error('Logging error: ', error)
			})
	}

	return (
		<>
			{/* <input value={email} name="email" onChange={handleEmailChange} />
			<input value={password} name="password" type="password" onChange={handlePasswordChange} />
			<button onClick={handleLogin}>Log in</button> */}

			<div className="form-group">
				<FormInput 
					name="login" 
					label="Email" 
					onChange={handleEmailChange}
					/>

				<FormInput 
					name="password" 
					label="Hasło" 
					type="password"
					onChange={handlePasswordChange}
				/>
			</div>
			
			<FormButton 
				className="centered" 
				onClick={handleLogin}
				label="Zaloguj się" 
			/>

		</>
	)
})

export default LoginForm