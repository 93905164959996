import React, { useState } from "react";
import OutsideClickHandler from 'react-outside-click-handler';
import { useEffect } from "react";
import FormLabel from "./FormLabel";

export default function FormDropdown({selectedOption, onChangeOption, placeholder = '', width, name, options, label, validationMessage = '', handleSelect, className, ...props}) {
	
	const [dropped, setDropped] = useState(false)

	const toggleDropdown = () => {
		setDropped(!dropped)
	}

	const handleOutsideClick = () => {
		setDropped(false)
	}

	const handleClick = (optionIndex) => {
		setDropped(false)
		if (onChangeOption) {
			onChangeOption(optionIndex)
		}
	}

	const style = {
		width: width ? width : "120px"
	}

	return (
			<div className={`form-element ${className}`} {...props}>
				<FormLabel label={label} validationMessage={validationMessage} />
				<div className={`dropdown-group ${dropped ? "dropped" : ""}`}>
						<div className="dropdown-field bordered-input" onClick={toggleDropdown} style={style}>
							<OutsideClickHandler onOutsideClick={handleOutsideClick}>
								<div>
									{
										selectedOption >= 0 && selectedOption < options.length ?
											<div>{options[selectedOption]}</div>
										:
											<div>{placeholder}</div>
									}
									<div className="dropdown-icon">v</div>
								</div>
							</OutsideClickHandler>
						</div>
					<div className="bordered-input dropdown-options" style={style}>
						{
							options.map((option, index) => (
								<div key={index} className="dropdown-option" onClick={() => handleClick(index)}>{option}</div>
							))
						}
					</div>
				</div>
			</div>
	)

}
