import React, { useState } from "react"
import FormButton from "../form/FormButton"
import { TablesDataContext } from "../../core/Context"
import { useContext } from "react"
import SyncTable from "./SyncTable"
import DataSnapshot from "../../logic/DataSnapshot"
import { useEffect } from "react"

// const nightmare = require('nightmare')({show: true})

export default function UpdateDataButton({logged, ...props}) {

	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const tablesDataContext = useContext(TablesDataContext)

	useEffect(() => {
		loadDataFromSnapshot()

		// console.log('lel')
		// DataSnapshot.setOnUpdateCallback(null)

		// DataSnapshot.setOnUpdateCallback(() => {
		// 	loadDataFromSnapshot()
		// })

		const interval = setInterval(() => {
			loadDataFromSnapshot()
		}, 1000 * 60 * 60)

		return () => clearInterval(interval)

	}, [])

	const loadDataFromSnapshot = () => {
		setIsLoading(true)
		setTimeout(() => {
			DataSnapshot.downloadDataSnapshotWithoutGeneratingNew()
			.then(data => {
				setError(null)
				setIsLoading(false)
				tablesDataContext.setCollections(data)
				console.log("Data loaded: ", data)
			})
			.catch(error => {
				setIsLoading(false)
				setError(error)
				console.log("Error: ", error)
			})
		}, 500.0)
	}

	const handleSubmit = () => {

		console.log("Loading data...")

		setIsLoading(true)
		setTimeout(() => {
			DataSnapshot.downloadDataSnapshot()
			.then(data => {
				setError(null)
				setIsLoading(false)
				tablesDataContext.setCollections(data)
				console.log("Data loaded: ", data)
			})
			.catch(error => {
				setIsLoading(false)
				setError(error)
				console.log("Error: ", error)
			})
		}, 500.0)
	}

	return (
		<>
		{
			logged ? 
				<FormButton 
					className="centered" 
					disabled={isLoading}
					onClick={handleSubmit}
					label={isLoading ? "Pobieranie danych..." : "Pobierz najnowsze dane"} 
				/>
			: null
		}
		{
			error ? <p>Wystąpił błąd podczas pobierania danych, spróbuj ponownie za chwilę.</p> : null
		}
		{
			tablesDataContext ?
				<>
					<h2>Uzysk z instalacji na kolektorach</h2>
					<SyncTable collectionName='collectors' />
					<h2>Uzysk z instalacji na panelach folowolaticznych</h2>
					<SyncTable collectionName='panels' />
				</>
			:
				null
		}
		</>
	)
}