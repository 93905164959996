import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

import CrashForm from "./CrashForm";
import SyncTable from "./SyncTable";
import LoginWindow from "./LoginWindow";
import UpdateDataButton from "./UpdateDataButton";

export default class MarkdownRenderer extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
	}

    render() {

		const languages = {
			image: (value) => {
				let params = value.split(',').map((x) => x.trim())
				let url = params[0]
				let size = params[1]
				return <img alt="" src={url} width={size}/>
			},
			crashForm: (value) => {
				return <CrashForm />
			},
			syncTable: (value) => {
				return <SyncTable collectionName={value}/>
			},
			loginWindow: (value) => {
				return <LoginWindow />
			},
			updateDataButton: (value) => {
				return <UpdateDataButton />
			}
		}

        const renderers = {
            code: ({language, value}) => {
				if (language in languages) {
					return languages[language](value)
				}

                const className = language && `language-${language}`
                const code = React.createElement('code', className ? {className: className} : null, value)
				return code
				// return React.createElement('pre', {}, code)
            }
        }

        return (
			<ReactMarkdown source={this.props.source} renderers={renderers} />
        );

    }

}
