import React, { Component } from "react";
import FormLabel from "./FormLabel";

export default function FormCheckbox({selected, toggle, text, label, validationMessage = '', className, ...props}) {
    
    return (
        <div className={`form-element ${className}`}>
            <FormLabel label={label} validationMessage={validationMessage} />
            <div className="checkbox-group">
                <div className="checkbox-column">
                    <div className={`checkbox bordered-input ${selected ? "checked" : ""}`} onClick={toggle}>
                        <div className={`checkbox-inside`}></div>
                    </div>
                </div>
                <div className="text-column">
                    {text}
                </div>
            </div>
        </div>
    )

}
