import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
	apiKey: "AIzaSyBpA0paSqYHQ3VpjJE8AD6ktVSrWLnVydQ",
	authDomain: "domotermika-cd071.firebaseapp.com",
	databaseURL: "https://domotermika-cd071.firebaseio.com",
	projectId: "domotermika-cd071",
	storageBucket: "domotermika-cd071.appspot.com",
	messagingSenderId: "561299467674",
	appId: "1:561299467674:web:ca694f5a0fbde72c399e23",
	measurementId: "G-SFVRQ1LXVJ"
};

firebase.initializeApp(config)

const Firebase = {
	auth: firebase.auth(),
	firestore: firebase.firestore(),
	storage: firebase.storage(),
	Auth: firebase.auth.Auth 
}

export default Firebase