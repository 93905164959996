import React from "react";
import FormInput from "../form/FormInput";
import FormButton from "../form/FormButton";

export default function LoginWindow(props) {

	const handleLogin = () => {
		
	}

	return (
		<>
			<div className="form-group">
				<FormInput name="login" label="Login" />
				<FormInput password name="password" label="Hasło" />
			</div>

			<div className="form-group">
				<FormButton label={"Zaloguj się"} onClick={handleLogin} />
			</div>
		</>
	)
}
