import moment from 'moment'

import Api from "../api/Api"
import Database from "../services/global/Database"

const justDownloadDataSnapshot = () => {
	return Database.downloadDataSnapshot()
	.catch(error => console.error(error))
}

const prepareDataSnapshotAndDownload = () => {
	return Api.prepareDataSnapshot()
	.catch(error => console.error(error))
}

const checkLastUpdate = (hours) => {

	return new Promise(async (resolve, reject) => {
		const lastUpdateData = await Database.downloadLastUpdate()
		const now = moment()
		const lastUpdate = moment(lastUpdateData.date.toDate())
		const diff = now.diff(lastUpdate, 'h')

		// Update every x 'hours'
		if (diff >= hours) {
			const newTimestamp = {
				date: now.toDate()
			}
			await Database.updateLastUpdate(newTimestamp)
			console.log('Updated "lastUpdate": ', newTimestamp)
			resolve(true)
		}
		else {
			console.log('Next update available in: ', hours - diff, ' hours.')
			resolve(false)
		}
	})
}

class DataSnapshot {

	setOnUpdateCallback(callback) {
		Database.onUpdateDataSnapshot(callback)
	}

	downloadDataSnapshotWithoutGeneratingNew() {
		return justDownloadDataSnapshot()
	}

	async downloadDataSnapshot () {
		
		return prepareDataSnapshotAndDownload()

		// const shouldUpdate = await checkLastUpdate(1)
	
		// if (shouldUpdate || true) {
		// 	console.log("Update data and download")
		// 	return prepareDataSnapshotAndDownload()
		// }
		// else {
		// 	console.log("Just download data")
		// 	return justDownloadDataSnapshot()
		// }
	}
}

export default new DataSnapshot