import CrashForm from "../content/CrashForm";
import React from "react";
import ReactDOM from "react-dom";
import { Component } from "react";
import SyncTable from "../content/SyncTable";
import UpdateDataButton from "../content/UpdateDataButton";

export default class EditorSyncTables {

	static get toolbox() {
		return {
			title: 'SyncTables',
			icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
		}
	}

	constructor({data, ...args}) {
		this.handleOnChange = this.handleOnChange.bind(this)
		this.collectionName = data.collectionName
	}

	handleOnChange(event) {
		this.collectionName = event.target.value
		
	}

	save(blockContent) {
		return {
			collectionName: this.collectionName
		}
	}
	
	render() {
		var div = document.createElement('div')
		const node = ReactDOM.render(
			<>
				{/* Sync table: <input value={this.collectionName} onChange={this.handleOnChange}/> */}
				<UpdateDataButton />
			</>
		, div)
		return div
	}
}