import Firebase from '../../core/Firebase'

const auth = Firebase.auth

class Auth {
	
	loginWithEmailAndPassword(email, password) {
		return auth.setPersistence(Firebase.Auth.Persistence.SESSION)
		.then(() => auth.signInWithEmailAndPassword(email, password)) 
		.catch(error => console.error(error))
	}
	
	logout() {
		return Firebase.auth.signOut()
	}
	
	onAuthStateChanged(callback) {
		return Firebase.auth.onAuthStateChanged(callback)
	}
}

export default new Auth