import Firebase from '../../core/Firebase'

const getDoc = (collection, document) => {
	return Firebase.firestore.collection(collection).doc(document)
}

const uploadDoc = (collection, document, data) => {
	console.log("Upload data.")
	return Firebase.firestore.collection(collection).doc(document).update(data)
	.catch(error => {
		console.error(error)
	})
}

const downloadDoc = (collection, document) => {
	console.log("Download data.")
	return Firebase.firestore.collection(collection).doc(document).get()
	.then(doc => {
		if(!doc.exists) {
			console.warn('No such document: ', document)
		}
		else {
			return doc.data()
		}
	})
	.catch(error => {
		console.error(error)
	})
}

class Database {
	downloadLastUpdate() {
		return downloadDoc('krasnobrod', 'lastUpdate')
	}
	
	updateLastUpdate(content) {
		return uploadDoc('krasnobrod', 'lastUpdate', content)
	}
	
	downloadDataSnapshot() {
		return downloadDoc('krasnobrod', 'dataSnapshotToImport')
	}
	
	updateDataSnapshot(content) {
		return uploadDoc('krasnobrod', 'dataSnapshotToImport', content)
	}
	
	downloadWebsiteContent() {
		return downloadDoc('krasnobrod', 'websiteContent')
	}
	
	updateWebsiteContent(content) {
		return uploadDoc('krasnobrod', 'websiteContent', content)
	}
	
	downloadWebsiteConfig() {
		return downloadDoc('krasnobrod', 'websiteConfig')
	}
	
	updateWebsiteConfig(content) {
		return uploadDoc('krasnobrod', 'websiteConfig', content)
	}
	
	onUpdateDataSnapshot(callback) {
		getDoc('krasnobrod', 'dataSnapshotToImport').onSnapshot(callback)
	}
}

export default new Database