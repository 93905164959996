import React from "react"

import NavbarButton from "./NavbarButton"
import ConfigManager from "../../logic/ConfigManager"
import Auth from "../../services/global/Auth"

const Navbar = ({websiteContent, logged, ...props}) => {

	const handleExportConfig = () => {
		console.log("Downloading config...")
		ConfigManager.exportConfig()
		.then(res => console.log("Config exported"))
	}

	const handleImportConfig = (event) => {
		console.log(event.target.files)
		const blob = event.target.files[0]
		const target = event.target
		if (blob) {
			ConfigManager.importConfig(blob)
			.finally(() => {
				target.value = ''
			})
		}
	}

	const handleLogout = () => {
		Auth.logout()
	}

	return (
		<div className="navbar">
			<div className="logo-background">
				<img alt="Logo" className="logo" src="/assets/krasnobrod_herb.png" />
				<div className="website-title">Montaż Odnawialnych Źródeł Energii na terenie Gminy Krasnobród</div>
			</div>
			{
				websiteContent && logged ?
					<>
						<button onClick={handleLogout}>Logout</button>
						<button onClick={handleExportConfig}>Export config</button>
						<input type="file" onChange={handleImportConfig}/>
						{/* <button onClick={handleImportConfig}>Import config</button> */}
					</>
				:
					null
			}
			<div className="navbar-background">
				{
					websiteContent ? 
						websiteContent.pages.filter(page => page.secret !== true).map(page => (
							<NavbarButton key={page.buttonUrl} url={page.buttonUrl} label={page.button} />
						))
					:
						<NavbarButton url={'/'} label={'...'} />
				}
			</div>
		</div>
	)
}

export default Navbar