import ProjectConfig from '../ProjectConfig'

const host = {
	local: 'http://localhost:5000/domotermika-cd071/us-central1',
	global: 'https://us-central1-domotermika-cd071.cloudfunctions.net'
}

const localEndpoints = {
	sendEmail: `${host.local}/sendEmail`,
	prepareDataSnapshot: `${host.local}/prepareDataSnapshot`
}

const globalEndpoints = {
	sendEmail: `${host.global}/sendEmail`,
	prepareDataSnapshot: `${host.global}/prepareDataSnapshot`
}

const endpoints = ProjectConfig.useLocalhostApi ? localEndpoints : globalEndpoints

class Api {

	sendEmail(parameters) {
		return fetch(endpoints.sendEmail, {
			method: 'POST',
			body: JSON.stringify(parameters)
		})
		.then(response => console.log(response.json()))
		.catch(error => console.error(error))
	}

	prepareDataSnapshot() {
		return fetch(endpoints.prepareDataSnapshot)
		.then(response => response.json())
		.catch(error => console.error(error))
	}
	
}

export default new Api