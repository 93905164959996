import React from "react";

import Editor from "../components/editor/Editor";

export default function Page({logged, page, updatePageContent, ...props}) {

	return (
		<>
			<div className="page">
				<div className="page-content">
					<Editor logged={logged} data={page.content} updatePageContent={updatePageContent}/>
				</div>
			</div>
		</>
	)

}
